import * as React from 'react';
import {
    Box,
    Divider,
    Stack,
    Typography
} from '@mui/material';
import {CommentBase} from '../../types';
import {TitleCloseableDrawer} from '../../../hf-title-closeable-drawer/hfTiteCloseableDrawer';
import {getService} from '../../../../../../migration_utils/react-in-angular';
import CommentTypeHandler from '../../comment-type-handler/CommentTypeHandler';
import HfConversation from '../../../hf-conversation/hfConversation';



interface CommentConversationDrawerProps {
    comment: CommentBase;
    showDrawer: boolean;
    onClose: () => void;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const CommentConversationDrawer = ({ comment, showDrawer,  onClose, onCommentUpdated } : CommentConversationDrawerProps) => {



    const $translate = getService('$translate');

    const [hasStartedConversation, setHasStartedConversation] = React.useState(false);


    const handleClose = () => {

        onClose();
        onCommentUpdated(null, comment.id);
        if (hasStartedConversation) {
            onCommentUpdated(null, comment.id);
            setHasStartedConversation(false);
        }

    }



    return (
        <TitleCloseableDrawer
            title={$translate.instant('CONVERSATIONS_MODAL_TITLE')}
            anchor={'right'}
            open={showDrawer}
            onClose={handleClose}
            sx={{maxWidth: '200px', zIndex: 3000}}
        >
            <Stack spacing={2}
                height={'100%'}>
                <CommentTypeHandler comment={comment}
                    showReplyWithAnnouncementLink={false}
                    showConversationLink={false}
                    onCommentUpdated={onCommentUpdated}/>

                <Divider/>

                <Typography variant={'caption'} fontWeight={'bold'}>
                    {$translate.instant('CONVERSATION')}
                </Typography>


                <Box sx={{
                    flexGrow: 1,
                    overflowY: 'auto'
                }}>
                    <HfConversation
                        commentId={comment.id}
                        conversationId={comment.conversationId}
                        onConversationStarted={(err, conversationId) => {setHasStartedConversation(true)}}/>

                </Box>


            </Stack>
        </TitleCloseableDrawer>
    );
};

export default CommentConversationDrawer;

