import React, { useState, useEffect } from 'react';
import Dates from '../../../../utilities/date.utilities';

import { getService } from 'reactInAngular';
import { Box, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import EngagementScoresSummaryRow from './components/ScoreSumaryRow';

interface EngagementScoresSummaryBoxProps {
    to: Date;
    dateGrouping: string;
    onShowDetail: (scoreId: string) => void;
}


const EngagementScoresSummaryBox: React.FC<EngagementScoresSummaryBoxProps> = ({ to, dateGrouping, onShowDetail }) => {
    const [scores, setScores] = useState<any[]>([]);

    const Scores = getService('Scores');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    useEffect(() => {
        Scores.list({}, (err: any, results: any[]) => {
            if (err) {
                // Handle errors
                ErrorSvrc.showErrorModal(err);
            } else {
                setScores(results);
            }
        });
    }, []);

    return (
        <Stack gap={1}>
            <Typography variant="h2">{$translate.instant('ENGAGEMENT_SCORES_SUMARY_BOX')}</Typography>

            <Typography variant="caption" color="textSecondary"
                dangerouslySetInnerHTML={{
                    __html: $translate.instant('ENGAGEMENT_SCORES_SUMMARY_AS_OF', {
                        days: moment(to).format(Dates.getFormatFromGrouping(dateGrouping))
                    })
                }}
            />

            {scores.map((score, index) => (
                <Stack key={index} gap={2}>
                    <EngagementScoresSummaryRow
                        onShowDetail={onShowDetail}
                        score={score}
                        to={to}
                        dateGrouping={dateGrouping}
                    />

                    {index < scores.length - 1 && (
                        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                            <Divider sx={{ width: '80%' }} />
                        </Box>
                    )}
                </Stack>
            ))}
        </Stack>
    );
};

export default EngagementScoresSummaryBox;
