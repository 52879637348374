'use strict';

import Dates from "../../../../../../utilities/date.utilities";
import HfDropFileArea from 'root/app/shared/new-components/hf-drop-file-area/hfDropFileArea';

function AnnouncementsStandardTypeEditorController($scope, $rootScope, Announcements, Attachments, hfImageSearchModal, $translate, AuthSrvc, Settings, ErrorSvrc, toaster) {
    'ngInject';

    var _this = this;

    _this.dateFormat = Settings.defaultDateFormat;
    _this.defaultCoverImage = 'https://assets.myhappyforce.com/defaultImages/announcement_contents/announcements_cover_image1.png';
    _this.editHTML = false;
    _this.savingInprogress = false;
    _this.sendAll = true;

    _this.deleteAttachment = function (id) {
        var attachments = _this.announcement.attachments;
        if (!_this.announcement.id) {
            // If we are creating a new announcement, we can directly remove the attachment from the list
            Attachments.delete(id, function (err, data) {
                if (err) {
                    return console.error('ERROR', err);
                }
                _this.announcement.attachments = attachments.filter(
                    function (item) {
                        return item.id !== id;
                    }
                );
            });
        } else {
            // If we are editing an existing announcement, we keep the attachment in the list, the backend will clean it up
            _this.announcement.attachments = attachments.filter(
                function (item) {
                    return item.id !== id;
                }
            );
        }
    };

    _this.onUploadFile = function (files) {

        _this.announcement.attachments = _this?.announcement?.attachments ?  [..._this?.announcement?.attachments] : [];

        _this.announcement.attachments.push(...files)

        return _this.announcement.attachments;
    };

    _this.onContentUpdated = function (content) {

        _this.announcement.message.defaultValue = content;
    };

    _this.onMediaUpdated = function (media) {
        if (!_this.announcement.media) {
            _this.announcement.media = [];
        }

        return _this.announcement.media.push(
            media
        );
    };

    _this.validAnnouncementContent = function () {
        return _this.announcement.message.defaultValue && _this.announcement.message.defaultValue.replace(/(<(?!img|a\b)[^>]+>)/gi, '').length > 0;;
    };

    _this.validateDates = function () {
        if (_this.announcement.startDate && _this.announcement.endDate) {
            return _this.announcement.startDate <= _this.announcement.endDate;
        }
        return true;
    };

    _this.onSelectImage = function (image) {
        if (image)
            _this.announcement.coverImageURL = image;
        else
            _this.announcement.coverImageURL = _this.defaultCoverImage;
    };

    _this.save = function (isCreate, isDraft) {
        _this.announcement.sectionId = _this.sectionId;
        _this.announcement.type = 'STANDARD';
        _this.announcement.draft = isDraft;
        _this.announcement.effectiveDate = moment(_this.effectiveDate).format('DDMMYYYY');
        _this.announcement.expirationDate = moment(_this.expirationDate).format('DDMMYYYY');
        _this.savingInprogress = true;

        // This is a callback to allow the parent component to handle the save. Used for templates
        if (_this.onSaveCallback) {
            _this.onSaveCallback(_this.announcement, function (err, announcement) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                    _this.savingInprogress = false;
                    return;
                } else {
                    toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_CREATED')));
                    $rootScope.$broadcast('announcementCreated', { id: announcement.id, sectionId: announcement.sectionId });
                    if (_this.onAnnouncementEdited)
                        _this.onAnnouncementEdited(announcement.id);
                }
                _this.savingInprogress = false;
            });
        } else {
            if (isCreate)
                Announcements.create(_this.announcement, function (err, announcement) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        _this.savingInprogress = false;
                        return;
                    } else {
                        toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_CREATED')));
                        $rootScope.$broadcast('announcementCreated', { id: announcement.id, sectionId: announcement.sectionId });
                        if (_this.onAnnouncementEdited)
                            _this.onAnnouncementEdited(announcement.id);
                    }
                    _this.savingInprogress = false;
                });
            else {

                Announcements.update(_this.announcement.id, _this.announcement, function (err, announcement) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        _this.savingInprogress = false;
                    } else {
                        toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_UPDATED')));
                        $rootScope.$broadcast('announcementUpdated', { id: announcement.id, sectionId: announcement.sectionId, statusChange: true });
                        if (_this.onAnnouncementEdited)
                            _this.onAnnouncementEdited(announcement.id);
                    }
                    _this.savingInprogress = false;
                });
            }
        }
    };

    _this.publishAnnouncement = function () {
        if (!_this.announcement.id) {
            _this.save(true, false);
        } else {
            _this.save(false, false);
        }


    };

    _this.saveDraft = function () {
        if (_this.announcement.id) {
            _this.save(false, true);
        } else {
            _this.save(true, true);
        }
    };

    _this.showCoverImageSelector = function () {
        hfImageSearchModal
            .showImageSelector(_this.announcement.coverImageURL, _this.defaultCoverImage, _this.onSelectImage);
    };

    _this.$onInit = function () {
        if (_this.announcement.effectiveDate) {
            _this.effectiveDate = Dates.fromAPIFormat(_this.announcement.effectiveDate, Settings.apiDateFormat);
        } else {
            _this.effectiveDate = new Date();
        }

        if (_this.announcement.expirationDate) {
            _this.expirationDate = Dates.fromAPIFormat(_this.announcement.expirationDate, Settings.apiDateFormat);
        } else {
            _this.expirationDate = new Date();
            _this.expirationDate.setDate(_this.expirationDate.getDate() + 7);
        }
    };

    _this.cancel = function () {
        if (_this.onExitEdition)
            _this.onExitEdition();
    };
};

const hfAnnouncementStandardTypeEditor = angular.module('happyForceApp')
    .directive('hfAnnouncementStandardTypeEditor', function () {
        return {
            scope: {
                sectionId: '<',
                announcement: '<',
                onAnnouncementEdited: '<',
                onExitEdition: '<',
                onSaveCallback: '<'
            },
            replace: true,
            template: require('./announcement-standard-type-editor.html'),
            controller: AnnouncementsStandardTypeEditorController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfAnnouncementStandardTypeEditor;
