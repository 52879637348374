import React, {useEffect, useState} from 'react';
import {getService} from 'reactInAngular';
import Numbers from '../../../../utilities/number.utilities';
import Valuation from '../../../../utilities/valuation.utils';
import Dates from '../../../../utilities/date.utilities';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import {Box, Link, Stack, Typography} from '@mui/material';
import ScaleChart from '../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import QualityPopoverText from '../../shared/quality_popover/EngagementQualityPopover';
import {DataQuality} from '../../../../shared/types/common';

interface EngagementHIBoxProps {
    to: Date;
    dateGrouping: string;
    onShowDetail: () => void;
}

const EngagementHIBox: React.FC<EngagementHIBoxProps> = ({ to, dateGrouping, onShowDetail }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [hi, setHi] = useState<number | null>(null);
    const [hiColor, setHiColor] = useState<string>('');
    const [variation, setVariation] = useState<number | null>(null);
    const [globalBenchmark, setGlobalBenchmark] = useState<number | null>(null);
    const [benchmarkDifference, setBenchmarkDifference] = useState<number | null>(null);
    const [quality, setQuality] = useState<DataQuality>(DataQuality.NO_DATA);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState<boolean>(true);

    const $translate = getService('$translate');
    const HIService = getService('HIService');
    const ErrorSvrc = getService('ErrorSvrc');

    const getHI = () => {
        setLoading(true);
        const toFormatted = Dates.lastDayOfMonth(to);
        const params = { to: toFormatted, grouping: dateGrouping };

        HIService.stats.get(params, (err: any, stats: any) => {
            setLoading(false);
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    setHasEnoughActiveEmployees(false);
                } else {
                    console.error('ERROR getting Stats', err);
                }
            } else {
                setHasEnoughActiveEmployees(true);
                const seriesData = stats && stats.periodResults;
                setHi(Numbers.roundNumber(seriesData.periodIndex));
                setHiColor(Valuation.valuationColor(seriesData.valuation));
                setVariation(Numbers.roundNumber(seriesData.periodIndexVariation, 1));
                setGlobalBenchmark(Numbers.roundNumber(stats.globalBenchmark, 1));
                setBenchmarkDifference(
                    Numbers.roundNumber(
                        Numbers.roundNumber(seriesData.periodIndex) - stats.globalBenchmark,
                        1
                    )
                );
                setQuality(seriesData.quality);
            }
        });
    };

    useEffect(() => {
        getHI();
    }, [to, dateGrouping]);

    const getVariationBenchmarkText = (key: string, isVariation: boolean): string => {
        const keys = isVariation
            ? { previous: Numbers.roundNumber((hi || 0) - (variation || 0), 1), variation: variation }
            : { benchmark: globalBenchmark, difference: benchmarkDifference };

        const change = isVariation ? variation : benchmarkDifference;

        if (change === 0) {
            return $translate.instant(`${key}_NEUTRAL`, keys);
        } else if (change! > 0) {
            return $translate.instant(`${key}_POSITIVE`, keys);
        } else {
            return $translate.instant(`${key}_NEGATIVE`, keys);
        }
    };

    const slices = [
        {
            color: Valuation.valuationColor('BAD'),
            range: [0, 33],
            label: $translate.instant('HI_SLICE_FIRST'),
        },
        {
            color: Valuation.valuationColor('WARNING'),
            range: [33, 66],
            label: $translate.instant('HI_SLICE_SECOND'),
        },
        {
            color: Valuation.valuationColor('EXCELLENT'),
            range: [66, 100],
            label: $translate.instant('HI_SLICE_THIRD'),
        },
    ];

    const renderContent = () => {
        return (
            <HappyLoading loading={loading}>
                <Stack gap={1}>
                    {!hasEnoughActiveEmployees && (
                        <EmptyState
                            image="images/anonymous.png"
                            small
                            message={$translate.instant('NOT_ENOUGH_EMPLOYEES')}
                            submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE')}
                        />
                    )}
                    {hasEnoughActiveEmployees && quality === 'NO_DATA' && (
                        <Stack>
                            <Typography variant="bigNumber" color="textSecondary">
                                {$translate.instant('NO_DATA')}
                            </Typography>
                            <Typography variant="body3" color="textSecondary">
                                {$translate.instant('HI_NO_DATA')}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                {hasEnoughActiveEmployees && quality !== 'NO_DATA' && (
                    <Stack gap={3} direction="row" width="100%">
                        <Stack direction="column" gap={1} flex={2} width="50%" alignItems="baseline">
                            <Stack direction="row" alignItems="baseline" gap={1}>
                                <Typography variant="bigNumber" color={hiColor}>
                                    {hi}
                                </Typography>
                                <Typography variant="h2" color={hiColor}>
                                    pts
                                </Typography>
                            </Stack>
                            <Box width="100%">
                                <ScaleChart slices={slices} value={hi || 0} />
                            </Box>
                        </Stack>
                        <Stack
                            direction="column"
                            flex={2}
                            sx={{ borderLeft: '1px solid #E0E0E0' }}
                            paddingLeft={3}
                            gap={1}
                        >
                            <Typography
                                variant="body3"
                                dangerouslySetInnerHTML={{
                                    __html: getVariationBenchmarkText('ENGAGEMENT_HI_COMPANY_COMPARISON', true),
                                }}
                            />
                            <Typography
                                variant="body3"
                                dangerouslySetInnerHTML={{
                                    __html: getVariationBenchmarkText('ENGAGEMENT_HI_BENCHMARK_COMPARISON', false),
                                }}
                            />
                        </Stack>
                    </Stack>
                )}
            </HappyLoading>
        );
    };

    return (
        <Stack gap={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h2">{$translate.instant('HAPPINESS_INDEX')}</Typography>
                {!loading && (
                    <Link onClick={onShowDetail}>
                        {$translate.instant('ENGAGEMENT_VIEW_DETAIL')}
                    </Link>
                )}
            </Stack>
            <Stack direction="row">
                <Typography variant="caption" color="textSecondary">
                    {$translate.instant('ENGAGEMENT_SCORES_SUMMARY_AS_OF', {
                        days: moment(to).format(Dates.getFormatFromGrouping(dateGrouping)),
                    })}
                </Typography>
            </Stack>


            <QualityPopoverText quality={quality} status={status} />

            {renderContent()}
        </Stack>
    );
};


export default EngagementHIBox;

