import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import { Menu, Box, Button, Typography } from '@mui/material';
import HierarchyNestedMenuItem from './components/HierarchyNestedMenuItem';
import GroupNestedMenuItem from './components/GroupNestedMenuItem';
import SegmentNestedMenuItem from './components/SegmentNestedMenuItem';
import OptionNestedMenuItem from './components/OptionNestedMenuItem';
import DateNestedMenuItem from './components/DateNestedMenuItem';
import HFTag from '../hf-tag/hf-tag';
import angular from 'angular';
import FilterIcon from '@mui/icons-material/FilterAlt';
import {
    HierarchyFiltersConfig, GroupFiltersConfig,
    SegmentFiltersConfig, DateFiltersConfig,
    OptionFiltersConfig, Option,
    PillFilter, SegmentSelected, FilterSelected
} from './types';
import Dates from '../../../utilities/date.utilities';
import {getHierarchyById} from '../../services/migrated/hierarchy.service';

interface HFFilterMenuProps {
    hierarchyFiltersConfig?: HierarchyFiltersConfig;
    groupFiltersConfig?: GroupFiltersConfig;
    segmentFiltersConfig?: SegmentFiltersConfig;
    dateFiltersConfig?: DateFiltersConfig;
    optionFiltersConfig?: OptionFiltersConfig;
    tagColor?: string;
    selectedFilters: FilterSelected;
    updateFilters: (filters: FilterSelected) => void;
}

const HfFilterMenu = ({ hierarchyFiltersConfig, groupFiltersConfig,
    segmentFiltersConfig, dateFiltersConfig, optionFiltersConfig,
    tagColor, selectedFilters, updateFilters }: HFFilterMenuProps) => {
    const [tagFilters, setTagFilters] = useState<PillFilter[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const $translate = getService('$translate');
    const Languages = getService('Languages');
    const Groups = getService('Groups');
    const Characteristics = getService('Characteristics');
    const Settings = getService('Settings');
    const open = Boolean(anchorEl);

    const handleAddFilter = (type: string, options: any[] | any) => {

        const newFilters = { ...selectedFilters };

        if (!options || options.length === 0) {
            delete newFilters[type];
        } else {
            newFilters[type] = options;
        }

        updateFilters(newFilters);
    }


    /*
        This hook is responsible for updating the tags that are displayed on the filter menu.
     */
    useEffect(() => {



        if (selectedFilters) {


            const newTags = [];

            // 1. Check if the selected filter is a hierarchy filter

            if (hierarchyFiltersConfig && selectedFilters[hierarchyFiltersConfig.type]) {

                // First' remove all tags from the given type

                const selectedHierarchyFilters = selectedFilters[hierarchyFiltersConfig.type] as string[];
                selectedHierarchyFilters.forEach(hierarchyId => {


                    getHierarchyById(hierarchyId, (error, hierarchy) => {
                        if (hierarchy) {
                            newTags.push({
                                nonRemovable: hierarchyFiltersConfig.nonRemovable,
                                option: hierarchyFiltersConfig.title,
                                name: hierarchy.name,
                                type: hierarchyFiltersConfig.type,
                                id: hierarchy.id
                            });
                        }
                    });
                });
            }

            if (groupFiltersConfig && selectedFilters[groupFiltersConfig.type]) {
                // We have a group filter here.

                const selectedGroupFilters = selectedFilters[groupFiltersConfig.type] as string[];
                selectedGroupFilters.forEach(groupId => {
                    Groups.getGroup(groupId, (error, group) => {
                        if (error) {
                            console.error(error);
                        }

                        newTags.push({
                            nonRemovable: groupFiltersConfig.nonRemovable,
                            option: groupFiltersConfig.title,
                            name: group.name,
                            type: groupFiltersConfig.type,
                            id: group.id
                        });

                    });
                });



            }

            if (segmentFiltersConfig && selectedFilters[segmentFiltersConfig.type]) {
                // We have a segment filter here.

                const selectedSegmentFilters = selectedFilters[segmentFiltersConfig.type] as SegmentSelected[];
                selectedSegmentFilters.forEach(selectedSegment => {

                    // Retrieve the name of the segment and value from the API:
                    const characteristicName = Characteristics.getCharacteristicNameById(selectedSegment.id);
                    selectedSegment.values.forEach(valueId => {

                        const valueName = Characteristics.getCharacteristicValueName(selectedSegment.id, valueId);

                        newTags.push({
                            nonRemovable: segmentFiltersConfig.nonRemovable,
                            option: Languages.getTranslationFromUserLanguage(characteristicName),
                            name: Languages.getTranslationFromUserLanguage(valueName),
                            type: segmentFiltersConfig.type,
                            id: selectedSegment.id,
                            subId: valueId
                        });
                    });
                });


            }

            if (dateFiltersConfig) {


                // Check if we have a date filter on the selected filters
                dateFiltersConfig.filters.forEach(dateFilter => {

                    if (selectedFilters[dateFilter.type]) {
                        const date = selectedFilters[dateFilter.type] as Date;
                        newTags.push({
                            nonRemovable: dateFilter.nonRemovable,
                            option: dateFilter.title,
                            name: Dates.toAPIFormat(date, Settings.defaultDateFormat),
                            type: dateFilter.type,
                        });
                    }
                });
            }

            if(optionFiltersConfig) {
                // Check if we have an option filter on the selected filters
                optionFiltersConfig.filters.forEach(optionFilter => {
                    if (selectedFilters[optionFilter.type]) {
                        const selectedOptions = selectedFilters[optionFilter.type] as string[];
                        selectedOptions.forEach(optionId => {
                            const option = findOptionById(optionFilter.options, optionId);
                            if (option) {
                                newTags.push({
                                    nonRemovable: optionFilter.nonRemovable,
                                    option: optionFilter.title,
                                    name: option.title,
                                    type: optionFilter.type,
                                    id: option.id
                                });
                            } else {
                                console.error('Option not found', optionId);
                            }
                        });
                    }
                });

            }

            setTagFilters(newTags);

        }


    }, [selectedFilters]);

    const findOptionById = (options: Option[], id: string): Option | undefined => {
        for (const option of options) {
            if (option.id === id) {
                return option;  // If the ID matches, return the option.
            }

            // If there are children, perform a recursive search.
            if (option.children) {
                const found = findOptionById(option.children, id);
                if (found) {
                    return found;  // If found in children, return it.
                }
            }
        }
        return undefined;  // If nothing is found, return undefined.
    }

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleRemove = (tag: PillFilter) => {

        if (selectedFilters && selectedFilters[tag.type]) {

            // Get the current filters
            const currentFilters = selectedFilters[tag.type];

            if (currentFilters && currentFilters instanceof Array) {

                if (currentFilters[0]['values']) {
                    // We are working with a segment filter

                    // Segment filter
                    const updatedSegments = currentFilters.map(segment => {
                        if (segment.id === tag.id) {
                            // Filter the values to remove the subId
                            const updatedValues = segment.values.filter(value => value !== tag.subId);

                            // If there are remaining values, update the segment
                            if (updatedValues.length > 0) {
                                return { ...segment, values: updatedValues };
                            }
                            // If no values remain, return null
                            return null;
                        }
                        return segment;
                    }).filter(segment => segment !== null); // Filter out nulls (empty segments)

                    const updatedFilters = { ...selectedFilters };
                    updatedFilters[segmentFiltersConfig.type] = updatedSegments;
                    updateFilters(updatedFilters);
                } else  {
                    const newFilters = (currentFilters as any[]).filter(filter => filter !== tag.id);
                    const updatedFilters = { ...selectedFilters };
                    updatedFilters[tag.type] = newFilters;
                    updateFilters(updatedFilters);
                }
            } else {
                const updatedFilters = { ...selectedFilters };
                delete updatedFilters[tag.type];
                updateFilters(updatedFilters);
            }
        }
    }

    const renderTags = () => {
        if (!tagFilters || !tagFilters.length) {
            return null;
        }

        return (
            <Box sx={(theme) => ({
                display: 'flex', alignContent: 'center', gap: theme.spacing(1),
                flexDirection: 'row', flexWrap: 'wrap', overflow: 'hidden'
            })}>
                {
                    tagFilters.map((tag, tagIndex) => {
                        if (tag.nonRemovable) {
                            return <HFTag key={tag.name}
                                text={'<strong>' + tag.option + ': </strong>' + tag.name}
                                status={tagColor ? tagColor : 'neutral'} />;
                        } else {
                            return <HFTag key={tagIndex}
                                text={'<strong>' + tag.option + ': </strong>' + tag.name}
                                status={tagColor ? tagColor : 'neutral'}
                                onRemove={() => handleRemove(tag)} />;
                        }
                    })
                }
            </Box>
        )
    }



    return (
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1), flexDirection: 'row' })}>
            <Button
                variant="text"
                size="small"
                style={{ textTransform: 'none' }}
                onClick={handleClick}
                startIcon={<FilterIcon />}>
                <Typography variant={'caption'} sx={{ textTransform: 'capitalize' }}>
                    {$translate.instant('FILTER')}
                </Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {hierarchyFiltersConfig &&
                    <HierarchyNestedMenuItem
                        open={open}
                        type={hierarchyFiltersConfig.type}
                        filters={
                            (selectedFilters && selectedFilters[hierarchyFiltersConfig.type] as string[]) ?? []
                        }
                        onAddFilter={handleAddFilter}
                        mode={hierarchyFiltersConfig.mode}
                        title={hierarchyFiltersConfig.title}
                        nonRemovable={hierarchyFiltersConfig.nonRemovable}
                        hierarchies={hierarchyFiltersConfig.hierarchies} />}
                {groupFiltersConfig &&
                    <GroupNestedMenuItem
                        open={open}
                        type={groupFiltersConfig.type}
                        filters={
                            (selectedFilters && selectedFilters[groupFiltersConfig.type] as string[]) ?? []

                        }
                        onAddFilter={handleAddFilter}
                        mode={groupFiltersConfig.mode}
                        title={groupFiltersConfig.title}
                        nonRemovable={groupFiltersConfig.nonRemovable}
                        groups={groupFiltersConfig.groups} />}
                {segmentFiltersConfig &&
                    <SegmentNestedMenuItem
                        open={open}
                        type={segmentFiltersConfig.type}
                        segments={segmentFiltersConfig.segments}
                        filters={
                            (selectedFilters && selectedFilters[segmentFiltersConfig.type] as SegmentSelected[]) ?? []
                        }
                        onAddFilter={handleAddFilter}
                        title={segmentFiltersConfig.title}
                        mode={segmentFiltersConfig.mode}
                        nonRemovable={segmentFiltersConfig.nonRemovable} />}
                {optionFiltersConfig &&
                    <>{optionFiltersConfig?.filters?.map((optionFilter, index) => (
                        <OptionNestedMenuItem
                            key={index}
                            open={open}
                            options={optionFilter.options}
                            filters={selectedFilters[optionFilter.type] as string[] | boolean[]}
                            onAddFilter={handleAddFilter}
                            title={optionFilter.title}
                            type={optionFilter.type}
                            mode={optionFilter.mode}
                            nonRemovable={optionFilter.nonRemovable} />
                    ))}</>}
                {dateFiltersConfig &&
                    <>{dateFiltersConfig?.filters.map((dateFilter, index) => (
                        <DateNestedMenuItem
                            key={index}
                            open={open}
                            filters={selectedFilters[dateFilter.type] as Date}
                            onAddFilter={handleAddFilter}
                            title={dateFilter.title}
                            type={dateFilter.type}
                            nonRemovable={dateFilter.nonRemovable} />
                    ))}</>}
            </Menu>
            {renderTags()}
        </Box>
    );

};

angularize(HfFilterMenu, 'hfFilterMenu', angular.module('happyForceApp'), {
    hierarchyFiltersConfig: '<',
    groupFiltersConfig: '<',
    segmentFiltersConfig: '<',
    optionFiltersConfig: '<',
    dateFiltersConfig: '<',
    selectedFilters: '<',
    updateFilters: '<',
    tagColor: '<',
});

export default HfFilterMenu;
