import React, { useState, useEffect } from 'react';
import {Box, Stack, Tooltip, Typography} from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import {CommentBase} from '../../../types';
import {ThumbDownOutlined} from '@mui/icons-material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CommentConversationDrawer from '../../comment-conversation-modal/CommentConversationDrawer';

interface CommentConversationActionProps {
    comment: CommentBase;
    onCommentUpdated?: (err: any, updatedCommentId: string) => void;
}

const CommentConversationAction: React.FC<CommentConversationActionProps> = ({ comment, onCommentUpdated }) => {
    const People = getService('People');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');
    const [conversationOwner, setConversationOwner] = useState<string>('');
    const [showConversationModal, setShowConversationModal] = useState(false);

    useEffect(() => {
        if (comment.conversationOwnerId) {
            People.getProfile(comment.conversationOwnerId, (err: any, user: { name: string }) => {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    setConversationOwner(user.name);
                }
            });
        }
    }, [comment.conversationOwnerId]);

    const showConversation = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        setShowConversationModal(true);
        const intercomContainer = document.getElementById('intercom-container');
        if (intercomContainer) {
            intercomContainer.style.display = 'block';
        }
    };

    const renderContent = () => {
        return (
            <Stack direction="row" display="flex" alignItems="center">
                <ChatBubbleOutlineOutlinedIcon/>

                {comment.conversationId &&
                    <Typography variant="caption" marginLeft={0.5}>
                        (1)
                    </Typography>}
            </Stack>
        );
    }


    return (
        <Box component="span">
            {comment.allowStartConversation ? (
                <a
                    onClick={showConversation}
                    style={{ display: 'flex' }}
                >
                    {renderContent()}
                </a>
            ) : (
                <Tooltip
                    title={$translate.instant('CONVERSATION_STARTED_BY_OTHER_DESCRIPTION', {
                        conversationOwner: conversationOwner,
                    })}
                    placement="left"
                >
                    {renderContent()}
                </Tooltip>
            )}

            <CommentConversationDrawer
                showDrawer={showConversationModal}
                comment={comment}
                onClose={() => setShowConversationModal(false)}
                onCommentUpdated={onCommentUpdated}/>

        </Box>
    );
};

export default CommentConversationAction;

