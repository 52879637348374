import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { angularize, getService } from 'reactInAngular';

import swal from 'sweetalert';
import angular from 'angular';
import {Stack, Tooltip} from '@mui/material';
import { Box, Typography, Button, TextField } from '@mui/material';
import HfUserSmall from '../../components/_migrated/user-small/hfUserSmall';
import HappyLoading from '../../components/_migrated/loader/loading.directive';
import {HfBannerReact} from '../hf-banner/hfBanner';
import HierarchyName from '../hf-hierarchy-name';
import Style from '../../../utilities/style.utils';

interface HfConversationProps {
    conversationId: string;
    commentId: string;
    personalTokenId: string;
    onConversationStarted: (err: any, conversationId: string) => void;
}


const HfConversation = ({ conversationId, commentId, personalTokenId, onConversationStarted } : HfConversationProps) => {
    const $translate = getService('$translate');
    const Conversation = getService('Conversation');
    const Analytics = getService('Analytics');
    const Settings = getService('Settings');

    const [loading, setLoading] = useState(false);
    const [conversation, setConversation] = useState(null);
    const [message, setMessage] = useState('');
    const [canMessage, setCanMessage] = useState(true);
    const [notAnonymous, setNotAnonymous] = useState(false);
    const [createdOn, setCreatedOn] = useState(null);
    const [sendingMessage, setSendingMessage] = useState(false);

    const [conversationCommentId, setConversationCommentId] = useState(commentId);
    const [conversationPersonalToken, setConversationPersonalToken] = useState(personalTokenId);



    const messagesEndRef = useRef(null);

    // Function to scroll to the bottom
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const fetchConversation = (conversationId) => {
        if (!conversationId) {
            setCanMessage(true);
            return;
        }

        setLoading(true);
        Conversation.get(conversationId,
            (err, data) => {
                if (err) {
                    return console.log(err);
                }

                setConversation(data);
                setMessage('');
                setLoading(false);
                setCanMessage(data.canMessage);
                setNotAnonymous(data.notAnonymous);
                setCreatedOn(moment(data.createdOn, Settings.apiDateFormat).toDate());

                setConversationCommentId(data.commentId);
                setConversationPersonalToken(data.encryptedId);

                Conversation.markAsSeen(conversationId, (err) => {
                    if (err) {
                        console.log(err);
                    }
                });
            });
    };

    const sendMessage = () => {
        if (!loading && message && message.length > 0) {
            setSendingMessage(true);
            if (!conversationId) {
                if (conversationCommentId) {
                    Conversation.startConversationOnComment(conversationCommentId, message, handleConversationMessageCallback);
                } else {
                    Conversation.startConversationOnPersonStatus(conversationPersonalToken, new Date(), message, handleConversationMessageCallback);
                }
            } else {
                Conversation.sendMessage(conversationId, message, handleConversationMessageCallback);
            }
        }
    };

    const handleConversationMessageCallback = (err, updatedConversation) => {
        setSendingMessage(false);
        if (err) {
            Analytics.trackEvent('conversations', 'send_reply', 'fail');
            return swal($translate.instant('ERROR_REPLY_CONVERSATION'), err);
        }

        Analytics.trackEvent('conversations', 'send_reply', 'success');
        setConversation(updatedConversation);
        setMessage('');

        if (!conversationId) {
            if (onConversationStarted) {onConversationStarted(err, updatedConversation.id);}
        }
    };

    const showDate = (date, prev) => {
        if (!prev) {return true;}
        const mActual = moment(date).format('DDMMYYYY');
        const mPrev = moment(prev).format('DDMMYYYY');
        return mPrev !== mActual;
    };



    useEffect(() => {
        fetchConversation(conversationId);
    }, [conversationId]);


    useEffect(() => {
        scrollToBottom();
    }, [conversation?.messages]);

    const renderMessage = (index, message) => <Stack
        key={index}
        display="flex"
        flexDirection="column"
        mb={2}
        mt={2}
        gap={1}
    >
        {showDate(
            message.date,
            conversation.messages[index - 1]?.date
        ) && (
            <Typography
                variant="caption"
                fontWeight="bold"
                color="textSecondary"
            >
                {moment(message.date).format('DD/MM/YYYY')}
            </Typography>
        )}
        <Box display="flex" justifyContent={message.userType === 'COMPANY_USER' ? 'flex-end' : 'flex-start'}>

            <Box
                sx={(theme) => ({
                    display: 'flex',
                    padding: theme.spacing(1),
                    borderRadius: '5px',
                    backgroundColor: message.userType === 'COMPANY_USER' ? Style.Blue200 : Style.Neutral050,
                    width: '80%'
                })}
                padding={1}
                className={`reply ${message.userType.toLowerCase()}`}
                display="flex"
                alignItems={message.userType === 'COMPANY_USER' ? 'flex-end' : 'flex-start'}
                flexDirection="column"
                gap={1}
            >
                <Typography variant="body3">
                    {message.message}
                </Typography>


                {message.userType === 'COMPANY_USER' && (
                    <Typography variant="caption">
                        <HfUserSmall userId={message.author} />
                    </Typography>
                )}
                {message.userType === 'EMPLOYEE' && (
                    <>
                        {notAnonymous ? (
                            <Typography variant="caption">
                                <HfUserSmall userId={message.author} />
                            </Typography>
                        ) : (
                            <Typography variant="caption">
                                <HierarchyName hierarchyId={conversation?.hierarchyId} />
                            </Typography>
                        )}
                    </>
                )}

                <Stack direction={'row'} gap={2}>

                    <Typography variant="caption" color="textSecondary">
                        {moment(message.date).format('HH:mm')}
                    </Typography>


                    {message.userType === 'COMPANY_USER' && (
                        <Tooltip  title={$translate.instant(
                            message.seen ? 'CONVERSATIONS_SEEN_BY_EMPLOYEE' : 'CONVERSATIONS_NOT_SEEN_BY_EMPLOYEE'
                        )}>
                            <Box component={'i'}

                                className={conversation.seen ? 'fa fa-check text-success' : 'fa fa-check warm-grey'}/>
                        </Tooltip>
                    )}
                </Stack>


            </Box>


        </Box>

    </Stack>;

    /*
    * Render the header of the conversation, displaying the assigned user and the date of the conversation
     */
    const renderHeader = () => {

        if (!conversation) {
            return null;
        }

        return (
            <>
                <Stack direction={'column'} gap={0.5}>
                    <HfUserSmall userId={conversation?.assignedTo}/>
                    <Typography variant="caption">{moment(createdOn).format('DD/MM/YYYY')}</Typography>
                </Stack>

                <Box>
                    <Typography variant="body3" fontWeight="bold">
                        {$translate.instant('CONVERSATION_N_REPLIES', {
                            messages: conversation?.messages?.length || 0,
                        })}
                    </Typography>
                </Box>
            </>
        );
    }

    return (

        <HappyLoading loading={loading}>
            <Stack direction={'column'} gap={2} height="100%" sx={{maxHeight: '100vh', overflow: 'hidden'}}>

                {renderHeader()}


                <Box
                    flexGrow={1}
                    sx={{
                        backgroundImage: 'url(images/timelineChatBg.svg)',
                        overflowY: 'scroll', // Ensure vertical scrolling
                        maxHeight: '100%', // Prevent overflow beyond the container
                    }}
                >
                    {!conversation?.signerId && (
                        <HfBannerReact type={'neutral'}>
                            <Typography variant="h6">
                                {$translate.instant('CONVERSATIONS_REMEMBER_ANONYMOUS')}
                            </Typography>
                        </HfBannerReact>
                    )}

                    {conversation?.messages ? (
                        <Box className="message-list">
                            {conversation?.messages?.map((message, index) => renderMessage(index, message))}


                            {/* Invisible div to ensure scrolling */}
                            <div ref={messagesEndRef}></div>
                        </Box>
                    ) : (
                        <Box textAlign="center" mt={2}>
                            <img
                                src="images/empty-state-comments.png"
                                alt={$translate.instant('CONVERSATIONS_NO_MESSAGES')}
                                style={{width: '100px'}}
                            />
                            <Typography variant="body2" color="textSecondary">
                                {$translate.instant('CONVERSATIONS_NO_MESSAGES')}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                {$translate.instant('CONVERSATIONS_START_THE_CONVERSATION')}
                            </Typography>
                        </Box>
                    )}
                </Box>


                {canMessage && (
                    <>
                        <TextField
                            variant="outlined"
                            fullWidth
                            minRows={1}
                            value={message}
                            multiline={true}

                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    sendMessage();
                                }
                            }}

                            onChange={(e) => setMessage(e.target.value)}
                            placeholder={$translate.instant('CONVERSATION_WILL_BE_SIGNED_WARNING')}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            disabled={loading || !message || message.length === 0 || sendingMessage}
                            onClick={sendMessage}
                        >
                            {$translate.instant('SEND_CONVERSATION_REPLY')}
                        </Button>
                    </>
                )}

                {!canMessage && (
                    <Box className="hf-banner" mt={3}>
                        <Typography variant="body2">
                            {$translate.instant('SEND_CONVERSATION_DISABLED_ONLY_OWNER_MESSAGE')}
                        </Typography>
                    </Box>
                )}
            </Stack>

        </HappyLoading>



    );
};

HfConversation.propTypes = {
    conversationId: PropTypes.string,
    commentId: PropTypes.string,
    personalTokenId: PropTypes.string,
    onConversationStarted: PropTypes.func
};

angularize(HfConversation, 'hfConversation', angular.module('happyForceApp'), {
    conversationId: '<',
    commentId: '<',
    personalTokenId: '<',
    onConversationStarted: '='
});

export default HfConversation;
