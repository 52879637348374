import Box from '@mui/material/Box';
import {RichTreeView} from '@mui/x-tree-view/RichTreeView';
import React, {useEffect, useState} from 'react';
import {Button, Popover} from '@mui/material';
import {getHierarchies, getHierarchyPath} from '../../../services/migrated/hierarchy.service';
import {getService} from '../../../../../migration_utils/react-in-angular';


interface UserAreaSelectorProps {
    hierarchyId: string;
    updateHierarchyId: (hierarchyId:string) => void;
    allowClear?: boolean;
    onClose: () => void;
    anchorEl: any;
}

/*
* This component is a popover that shows a tree of all the available hierarchies
 */
const AreaSelectorPopover = ({ anchorEl, updateHierarchyId, hierarchyId, onClose, allowClear=false }: UserAreaSelectorProps) => {


    const [hierarchyTree, setHierarchyTree] = useState([]);
    const [newHierarchyId, setNewHierarchyId] = useState(hierarchyId);

    const [hasHierarchyChanged, setHasHierarchyChanged] = useState(false);
    const [expandedHierarchyItems, setExpandedHierarchyItems] = useState<string[]>([]);

    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');


    useEffect(() => {
        getHierarchies((err, results) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else if(results && results !== '') {
                const buildTreeRecursively = (hierarchies) => {
                    return hierarchies?.map(hierarchy => {
                        return {
                            id: hierarchy.id,
                            label: hierarchy.name,
                            children: hierarchy.children ? buildTreeRecursively(hierarchy.children) : []
                        };
                    });

                };

                setHierarchyTree(buildTreeRecursively(results));
            }
        });
    }, []);

    useEffect(() => {
        setHasHierarchyChanged(newHierarchyId !== null && newHierarchyId !== hierarchyId);
    }, [newHierarchyId, hierarchyId]);



    useEffect(() => {
        if (newHierarchyId) {
            getHierarchyPath(newHierarchyId, (err, hierarchyPath) => {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    setExpandedHierarchyItems(hierarchyPath.map(hierarchy => hierarchy.id));

                }
            });
        }

    }, [newHierarchyId]);


    const handleAreaSelection = (event, itemId) => {
        event.stopPropagation();
        setNewHierarchyId(itemId);
    };

    const handleConfirmChange = () => {
        updateHierarchyId(newHierarchyId);
        onClose();
    }

    const handleClearArea = () => {
        updateHierarchyId(null);
        onClose();
    }

    const handleClose = () => {
        setNewHierarchyId(null);
        onClose();
    };

    return (
        <Popover
            id={'area-selector-popover'}
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box sx={theme => ({padding: theme.spacing(1)})}>
                <RichTreeView
                    sx={{height:'50vh', overflow: 'scroll'}}
                    items={hierarchyTree}
                    selectedItems={hierarchyId ? [hierarchyId] : []}
                    onItemSelectionToggle={handleAreaSelection}
                    expandedItems={expandedHierarchyItems}
                    onExpandedItemsChange={(event: React.SyntheticEvent,  itemIds: string[]) => {
                        setExpandedHierarchyItems(itemIds);
                    }}
                />
                {allowClear && (
                    <Button
                        sx={theme => ({marginTop: theme.spacing(1)})}
                        size={'small'}
                        color={'error'}
                        fullWidth
                        variant={'text'}
                        disabled={!hierarchyId}
                        onClick={handleClearArea}
                    >
                        {$translate.instant('EMPLOYEE_REMOVE_AREA')}
                    </Button>)}
                <Button
                    sx={theme => ({marginTop: theme.spacing(1)})}
                    size={'small'}
                    fullWidth
                    variant={'contained'}
                    disabled={!hasHierarchyChanged}
                    onClick={handleConfirmChange}>{$translate.instant('CONFIRM')}</Button>
            </Box>

        </Popover>
    );

};

export default AreaSelectorPopover;
