'use strict';

import CharacteristicAddModal from './components/characteristic_add/characteristic_add.controller';
import CharacteristicEditModal from './components/characteristic_edit/characteristic_edit.controller';
import CharacteristicDeleteModal from './components/characteristic_delete/characteristic_delete.controller';
import Scope from './../../../../utilities/scope.utilities';

function CharacteristicsConfigCtrl(Characteristics, CharacteristicsAddModal, CharacteristicsEditModal, $translate, $scope, ErrorSvrc, $uibModal, Languages, Analytics) {
    'ngInject'

    Scope.initialize($scope, ['characteristics'], []);

    $scope.selectedLanguage = null;

    $scope.selectedLanguageTranslation = null;

    $scope.valuesLanguages = [];

    function computeCurrentLanguages() {
        if ($scope.characteristics.length > 0) {
            const languages = new Set();

            // Get all the languages:
            $scope.characteristics.forEach(function (characteristic) {
                if (characteristic.question.langValues != null) {
                    Object.keys(characteristic.question.langValues).forEach(function (x) {languages.add(x);});
                }
            });

            $scope.valuesLanguages = [];
            languages.forEach(function (x) {$scope.valuesLanguages.push(x);});
        } else {
            $scope.valuesLanguages = [];
        }
    }


    function loadAllCharacteristics() {
        Characteristics.getAll(function (err, characteristics) {
            if (err) {
                ErrorSvrc.showErrorModal(response);
                $scope.isLoading = false;
                $scope.isError = true;
                $scope.loading = false;
            } else {
                $scope.characteristics = characteristics;
                $scope.loading = false;
                computeCurrentLanguages();
            }
        });
    }

    $scope.employeeProfileResetModes = [
        { id: 'NEVER', label: $translate.instant('EMPLOYEE_RESET_MODE_NEVER') },
        { id: 'EACH_30_DAYS', label: $translate.instant('EMPLOYEE_RESET_MODE_30DAYS') },
        { id: 'ALWAYS', label: $translate.instant('EMPLOYEE_RESET_MODE_ALWAYS') }
    ];

    $scope.characteristicType = [
        { id: 'DATE', label: $translate.instant('CHARACTERISTICS_TYPE_DATE') },
        { id: 'BOOLEAN', label: $translate.instant('CHARACTERISTICS_TYPE_YESNO') },
        { id: 'LIST', label: $translate.instant('CHARACTERISTICS_TYPE_LIST') }
    ];

    $scope.addCharacteristic = function () {
        CharacteristicsAddModal.showModal($scope.selectedLanguage, function (err) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                loadAllCharacteristics();
            }
        });
    };

    $scope.editCharacteristic = function (selectedCharacteristic) {
        CharacteristicsEditModal.showModal(angular.copy(selectedCharacteristic), $scope.selectedLanguage, function (err, editedCharacteristic) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else if(editedCharacteristic) {
                loadAllCharacteristics();
            }
        });
    };

    $scope.getCharacteristicTypeLabel = function (type) {
        var typeCharacteristic = _.find($scope.characteristicType, { id: type });
        if (typeof typeCharacteristic == 'undefined') {
            return $translate.instant('UNKNOWN');
        } else {
            return typeCharacteristic.label;
        }
    };

    $scope.getCharacteristicValues = function (characteristic) {
        var values = [];
        if (characteristic.questionType == 'DATE') {
            values = _.map(characteristic.ranges, function (value) { return value.name; });
        } else if (characteristic.questionType == 'LIST') {
            values = _.map(characteristic.values, function (value) { return value.value; });
        } else if (characteristic.questionType == 'BOOLEAN') {
            values.push(characteristic.yesValue);
            values.push(characteristic.noValue);

        } else {
            values.push($translate.instant('UNKNOWN'));

        }

        return values;
    };

    $scope.getCharacteristicById = function (characteristicId) {
        return _.find($scope.characteristics, { id: characteristicId });
    };


    $scope.selectLanguage = function (language) {
        $scope.selectedLanguage = language;
        $scope.selectedLanguageTranslation = Languages.getNameByCode(language);
    };

    $scope.addLanguage = function (language) {

        // For each value, add the new language
        $scope.characteristics.forEach(function (characteristic) {
            Languages.setLangValue(characteristic.question, null, language);
            if (characteristic.questionType == 'LIST') {
                characteristic.values.forEach(function (value) {
                    Languages.setLangValue(value.value, null, language);
                });
            } else if (characteristic.questionType == 'BOOLEAN') {
                Languages.setLangValue(characteristic.yesValue, null, language);
                Languages.setLangValue(characteristic.noValue, null, language);
            }
        });

        // Update the language list
        computeCurrentLanguages();

    };


    $scope.removeLanguage = function (language) {
        swal({
                type: 'warning',
                title: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_TITLE', { language: $scope.selectedLanguageTranslation }),
                text: $translate.instant('QUESTION_DELETE_LANGUAGE_MODAL_DESCRIPTION', { language: $scope.selectedLanguageTranslation }),
                allowEscapeKey: true,
                buttons: {
                    cancel: {
                        visible: true,
                        text: $translate.instant('QUESTION_DELETE_LANGUAGE_CANCEL', { language: $scope.selectedLanguageTranslation }),
                        value: false
                    },
                    confirm: {
                        text: $translate.instant('QUESTION_DELETE_LANGUAGE_CONFIRM', { language: $scope.selectedLanguageTranslation }),
                        value: true,
                        className: 'swal-button--delete'
                    }
                },
                confirmButtonColor: '#DD6B55',
                closeOnConfirm: true
            }).then(function (result) {
                if (result) {

                    var streams = [];
                    $scope.characteristics.forEach(function (characteristic) {

                        streams.push(function (next) {
                            // Remove the language
                            Languages.removeLangValue(characteristic.question, $scope.selectedLanguage);
                            if (characteristic.questionType == 'LIST') {
                                characteristic.values.forEach(function (value) {
                                    Languages.removeLangValue(value.value, $scope.selectedLanguage);
                                });
                            } else if (characteristic.questionType == 'BOOLEAN') {
                                Languages.removeLangValue(value.yesValue, $scope.selectedLanguage);
                                Languages.removeLangValue(value.noValue, $scope.selectedLanguage);
                            }

                            Characteristics.update(characteristic.id, characteristic, function (err, updatedCharacteristic) {
                                if (err) {
                                    next(err);
                                } else {
                                    next();
                                }
                            });

                        });

                        async.parallel(streams, function (err, results) {
                            if (err) {
                                ErrorSvrc.showErrorModal(err);
                            }
                            loadAllCharacteristics();
                        });
                    });

                }
            });
    };


    loadAllCharacteristics();
}

const hfCharacteristicsConfig =  angular.module('happyForceApp')
    .directive('characteristicsConfig', function () {
        return {
            template: require('./characteristics_config.html'),
            controller: CharacteristicsConfigCtrl,
            controllerAs: 'qcc',
            bindToController: true
        };
    });

export default hfCharacteristicsConfig;