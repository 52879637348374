import React from 'react';
import {Box, Stack, Tooltip, Typography} from '@mui/material';
import { getService } from 'reactInAngular';
import {StandardComment} from '../../../types';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

interface CommentReplyWithAnnouncementActionProps {
    comment: StandardComment;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const CommentReplyWithAnnouncementAction: React.FC<CommentReplyWithAnnouncementActionProps> = ({
    comment,
    onCommentUpdated,
}) => {
    const AnnouncementReplyToCommentModal = getService('AnnouncementReplyToCommentModal');
    const $translate = getService('$translate');

    const announcementCallback = () => {
        onCommentUpdated(null, comment.id);
    };

    const showAnnouncement = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        AnnouncementReplyToCommentModal.showModal(comment.id, announcementCallback);
    };

    const renderContent = () =>
        (
            <Stack direction="row" display="flex" alignItems="center">
                <CampaignOutlinedIcon/>

                {comment.announcementId && (
                    <Typography variant="caption" marginLeft={0.5}>
                        (1)
                    </Typography>
                )}


            </Stack>
        );

    return (
        <Box component="span">
            {!comment.privated ? (
                <a onClick={showAnnouncement}>
                    {renderContent()}
                </a>
            ) : (
                <Tooltip
                    title={$translate.instant('COMMENT_IS_PRIVATED_REPLY_WITH_ANNOUNCEMENT_ACTION_HINT')}
                    placement="top"
                >
                    {renderContent()}
                </Tooltip>
            )}
        </Box>
    );
};

export default CommentReplyWithAnnouncementAction;
