import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Numbers from '../../../../../../../utilities/number.utilities';
import Valuation from '../../../../../../../utilities/valuation.utils';
import EngagementDetailCharts from '../../../../../engagement_detail.charts';
import HFSwitch from '../../../../../../../shared/new-components/hf-switch/hf-switch.component';
import EmptyState from '../../../../../../../shared/components/_migrated/empty_state/empty_state';
import { Box, Typography, Grid, Stack } from '@mui/material';
import HfMultiLanguageText from '../../../../../../../shared/new-components/hf-multilanguage-text/hfMultilanguageText';
import HFInfoIconWithTooltip from '../../../../../../../shared/new-components/hf-info-icon-with-tooltip';
import {ScoreQuestionResult} from '../../../../../../../shared/types/scores';
import { DataQuality } from '../../../../../../../shared/types/common';
import Highcharts from 'highcharts';

interface ScoreQuestionRowProps {
    questionResults: ScoreQuestionResult;
    scoreId: string;
    factorId: string;
    questionNum: number;
    numQuestions: number;
    dateGrouping: string;
}

const ScoreQuestionRow: React.FC<ScoreQuestionRowProps> = ({ questionResults, scoreId, factorId, questionNum, numQuestions, dateGrouping }) => {
    const ScoresV2Service = getService('ScoresV2Srvc');
    const ErrorService = getService('ErrorSvrc');
    const $translate = getService('$translate');

    const [selectedMode, setSelectedMode] = useState('LAST_RESULT');
    const [questionDetails, setQuestionDetails] = useState<any>('');
    const [inverted, setInverted] = useState(false);
    const [result, setResult] = useState(0);
    const [variation, setVariation] = useState(0);
    const [previousResult, setPreviousResult] = useState(0);
    const [quality, setQuality] = useState(DataQuality.NO_DATA);
    const [valuationColor, setValuationColor] = useState('');
    const [variationText, setVariationText] = useState('');
    const [questionLastResultsChart, setQuestionLastResultsChart] = useState<any>({});
    const [questionEvolutionChart, setQuestionEvolutionChart] = useState<any>({});

    useEffect(() => {
        ScoresV2Service.getQuestionData(scoreId, factorId, questionResults.id, (err, questionInfo) => {
            if (err) {
                ErrorService.showErrorModal(err);
            } else if (questionResults && questionResults.results && questionResults.results.length > 0) {
                setQuestionDetails(questionInfo);
                setInverted(questionInfo.inverted);

                const lastResult = questionResults.results.slice(-1)[0];
                const chartResults = questionResults.results.map(result => [result.from, result.result]);

                setResult(Numbers.roundNumber(lastResult.result, 1));
                setVariation(Numbers.roundNumber(lastResult.variation, 1));
                setQuality(lastResult.quality);
                setValuationColor(Valuation.valuationColor(lastResult.valuation));

                setQuestionLastResultsChart(EngagementDetailCharts.scoreQuestionChart(questionInfo, lastResult));
                setQuestionEvolutionChart(EngagementDetailCharts.factorSparkLineEvolution(chartResults, dateGrouping, 90));

                const previousResultIndex = questionResults.results.length - 2;
                const previousResultValue = previousResultIndex >= 0 ? Numbers.roundNumber(questionResults.results[previousResultIndex].result, 1) : 0;

                setPreviousResult(previousResultValue);
            } else {
                setQuality(DataQuality.NO_DATA);
            }
        });
    }, [questionResults]);

    useEffect(() => {
        const keys = { variation, previous: previousResult };

        if (variation > 0) {
            setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_POSITIVE', keys));
        } else if (variation < 0) {
            setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEGATIVE', keys));
        } else {
            setVariationText($translate.instant('ENGAGEMENT_FACTOR_VARIATION_NEUTRAL', keys));
        }
    }, [variation, previousResult]);


    return (
        <Grid container spacing={2}>
            <Grid item sm={5} xs={12}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="caption" color="textSecondary">
                        {$translate.instant('ENGAGEMENT_QUESTION_COUNT', { total: numQuestions, number: questionNum })}
                    </Typography>


                    <Stack direction="row" gap={0.5}>
                        <Typography variant="body2" fontWeight="bold">
                            <HfMultiLanguageText multilanguage={questionDetails?.title} />
                        </Typography>
                        {inverted && (
                            <HFInfoIconWithTooltip
                                tooltipTitle={$translate.instant('SCORE_INVERTED_QUESTION_DESCRIPTION')}
                            />
                        )}

                    </Stack>

                    {quality !== DataQuality.NO_DATA && (

                        <Stack direction={'row'} display={'flex'} alignItems={'baseline'}>
                            <Typography variant="h4" fontSize={'24px'}  style={{ color: valuationColor }}>
                                {result}
                            </Typography>
                            <Typography variant="subtitle1" style={{ color: valuationColor }}>
                                pts
                            </Typography>
                        </Stack>
                    )}
                    {quality === DataQuality.NO_DATA && <Typography >{$translate.instant('NO_DATA')}</Typography>}
                    {quality !== DataQuality.NO_DATA && (
                        <Typography variant={'body3'} dangerouslySetInnerHTML={{ __html: variationText }} />
                    )}
                </Stack>

            </Grid>

            <Grid item sm={7} xs={12}>


                <Stack direction="column" spacing={2} alignItems={'center'}>
                    {quality === DataQuality.NO_DATA && (
                        <EmptyState image="images/no_scores.svg" small={true} message={$translate.instant('NO_DATA')} />
                    )}
                    {quality !== DataQuality.NO_DATA && (
                        <>
                            <HFSwitch
                                firstLabel={$translate.instant('LAST_RESULT_MODE')}
                                firstValue={'LAST_RESULT'}
                                secondLabel={$translate.instant('EVOLUTION_MODE')}
                                secondValue={'EVOLUTION'}
                                selected={selectedMode}
                                onSelect={(mode: string) => setSelectedMode(mode)}
                            />
                            <Box className="question-chart-container">
                                {selectedMode === 'LAST_RESULT' && (
                                    <HighchartsReact highcharts={Highcharts} options={questionLastResultsChart} />
                                )}
                                {selectedMode === 'EVOLUTION' && (
                                    <HighchartsReact highcharts={Highcharts} options={questionEvolutionChart} />
                                )}
                            </Box>
                        </>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default ScoreQuestionRow;
