import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { getService } from 'reactInAngular';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import HfFilterMenu from '../../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import HFCircleDot from '../../../../shared/new-components/hf-circle-dot/hf-circle-dot';
import {
    FilterSelected,
    GroupFiltersConfig,
    HierarchyFiltersConfig, OptionFiltersConfig,
    SegmentFiltersConfig
} from '../../../../shared/new-components/hf-filter-menu/types';
import ColorUtilities from '../../../../utilities/color.utilities';
import { Stack } from '@mui/material';
import moment from 'moment/moment';
import HFInfoIconWithTooltip from '../../../../shared/new-components/hf-info-icon-with-tooltip';
import Dates from '../../../../utilities/date.utilities';
import { EngagementAnalyzeBoxChart, EngagementAnalyzeBoxResult } from './components/EngagementAnalyzeBoxChart';
import { getENPSStats } from '../../../../shared/services/migrated/enps.service';
import { APISettings } from '../../../../shared/services/migrated/axiosWrapper';


interface ComparisonGroup {
    id: string;
    name: string;
    color: string;
    tagColor: string;
    filters: FilterSelected;
    duplicate: boolean;
    hasData?: boolean;
    hierarchyFiltersConfig: HierarchyFiltersConfig;
    groupFiltersConfig: GroupFiltersConfig;
    segmentFiltersConfig: SegmentFiltersConfig;
}

interface EngagementAnalyzeBoxProps {
    to: Date;
    dateGrouping: string;
}

const EngagementAnalyzeBox: React.FC<EngagementAnalyzeBoxProps> = ({ to, dateGrouping }) => {
    const [fromDate, setFromDate] = useState<Date>();
    const [comparisonGroups, setComparisonGroups] = useState<ComparisonGroup[]>([]);
    const [indicatorsFilters, setIndicatorsFilters] = useState<FilterSelected>({
        primary: [],
        score: []
    });
    const [indicatorsFiltersConfig, setIndicatorsFiltersConfig] = useState<OptionFiltersConfig>({filters: []});
    const [results, setResults] = useState<EngagementAnalyzeBoxResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [scoreAndFactorNames, setScoreAndFactorNames] = useState<{ [key: string]: string }>({});



    const $translate = getService('$translate');
    const Scores = getService('Scores');
    const toaster = getService('toaster');
    const Languages = getService('Languages');
    const SegmentsFilterService = getService('SegmentsFilterService');

    const SegmentationNamesService = getService('SegmentationNamesService');
    const CompanySvrc = getService('CompanySvrc');

    const HIService = getService('HIService');
    const ErrorSvrc = getService('ErrorSvrc');
    const ScoresV2Srvc = getService('ScoresV2Srvc');


    /*
    * Config for the option filters for segment, group and hierarchy
     */
    const hierarchyFiltersConfig: HierarchyFiltersConfig = {
        id: 'HIERARCHY',
        type: 'hierarchyIds',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false,
    };

    const groupFiltersConfig: GroupFiltersConfig = {
        id: 'GROUP',
        type: 'groups',
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false,
        groups: null,
    };


    const segmentFiltersConfig: SegmentFiltersConfig = {
        id: 'SEGMENT',
        type: 'segments',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false,
        segments: null
    };


    useEffect(() => {
        // Let's build the primarty filters
        const optionFiltersConfig = {
            filters: [
                {
                    title: $translate.instant('HI_ENPS'),
                    type: 'primary',
                    mode: 'multi',
                    nonRemovable: false,
                    options: [
                        {
                            title: $translate.instant('HI'),
                            id: 'HI'
                        },
                        {
                            title: $translate.instant('ENPS'),
                            id: 'ENPS'
                        }
                    ]
                },
            ]
        };

        // Load the scores

        const scoreFilterOptions = [];

        Scores.list({}, (err: any, scores: any) => {
            if (err) {
                toaster.error($translate.instant('ERROR_LOADING_SCORES'));
            } else {
                scores.forEach((score: any) => {

                    const scoreName = Languages.getTranslationFromUserLanguage(score.name);

                    const scoreEntry = {
                        title: scoreName,
                        id: 'SCORE_' + score.id,
                        children: []
                    };



                    setScoreAndFactorNames((prev) => ({ ...prev, [score.id]: scoreName }));

                    score.factors.forEach((factor: any) => {
                        const factorName = Languages.getTranslationFromUserLanguage(factor.name);

                        const factorEntry = {
                            title: factorName,
                            id: 'FACTOR_' + score.id + '_' + factor.id
                        };

                        scoreEntry.children.push(factorEntry);

                        setScoreAndFactorNames((prev) => ({ ...prev, [factor.id]: factorName }));

                    });

                    scoreFilterOptions.push(scoreEntry);

                });


                optionFiltersConfig.filters.push({
                    title: $translate.instant('SCORES_SECTION_TITLE'),
                    type: 'score',
                    mode: 'multi',
                    nonRemovable: false,
                    options: scoreFilterOptions
                });

                setIndicatorsFiltersConfig(optionFiltersConfig);
            }
        });



    }, []);

    useEffect(() => {
        setFromDate(Dates.firstDayOfMonth(moment(to).subtract(12, 'month').toDate()));
    },
        [to]);




    const buildNameFromGroup = (filters: FilterSelected): Promise<string> => {


        return new Promise((resolve, reject) => {
            if (filters) {
                SegmentationNamesService
                    .getNames(
                        filters['hierarchyIds'],
                        filters['segments'],
                        filters['groups'],
                        function (err, names) {
                            if (err) {
                                console.error('Error retrieving segment names', err);
                                reject(err);
                            } else {

                                // Let's compile the final name
                                const areaNames = [];
                                const segmentNames = [];
                                const groupNames = [];

                                const finalNames = [];
                                names.forEach(function (name) {
                                    if (name.type == 'hierarchy') { areaNames.push(name.name); }
                                    else if (name.type == 'segment') { segmentNames.push(name.name); }
                                    else if (name.type == 'group') { groupNames.push(name.name); }
                                });

                                if (areaNames.length > 0) { finalNames.push($translate.instant('EMPLOYEE_AREA') + ' ' + areaNames.join(', ')); }

                                if (segmentNames.length > 0) { finalNames.push($translate.instant('EMPLOYEE_PROFILE') + ' ' + segmentNames.join(', ')); }

                                if (groupNames.length > 0) { finalNames.push($translate.instant('EMPLOYEE_GROUPS') + ' ' + groupNames.join(', ')); }

                                resolve(finalNames.join('; '));
                            }
                        });
            } else {
                CompanySvrc.getUserCompany(function (err, company) {
                    if (!err) {
                        resolve(company.name);
                    } else {
                        reject(err);
                    }
                });
            }

        });


    };



    const buildHIPromise = (params: { hasFilters: boolean; from: Date; to: string }, comparisonGroup: ComparisonGroup): Promise<any> => {
        return new Promise((resolve, reject) => {


            HIService.stats.get(params, function (err, result) {
                const parsedResult: EngagementAnalyzeBoxResult = {
                    id: 'HI',
                    groupName: comparisonGroup.name,
                    metricName: 'HI',
                    group: comparisonGroup.id,
                    color: comparisonGroup.color,
                    results: [],
                    hasData: false,
                    hasEnoughActiveEmployees: true
                };

                if (err && ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    parsedResult.hasEnoughActiveEmployees = false;
                    resolve(parsedResult);
                } else if (err) {
                    reject(err);
                } else {
                    parsedResult.hasData = (result.quality != 'NO_DATA');
                    Object.keys(result.periodResults.votes).forEach(function (voteKey) {
                        if (result.periodResults.votes[voteKey].indexRating > 0) {
                            parsedResult.results.push(
                                {
                                    date: result.periodResults.votes[voteKey].date,
                                    value: result.periodResults.votes[voteKey].indexRating
                                }
                            );
                        }

                    });

                    resolve(parsedResult);
                }
            });
        });
    };


    const buildENPSPromise = (params: { hasFilters: boolean; from: Date; to: string }, comparisonGroup: ComparisonGroup): Promise<any> => {
        return new Promise((resolve, reject) => {
            getENPSStats(params, (err, result) => {
                const parsedResult: EngagementAnalyzeBoxResult = {
                    id: 'ENPS',
                    metricName: 'eNPS',
                    groupName: comparisonGroup.name,
                    group: comparisonGroup.id,
                    color: comparisonGroup.color,
                    results: [],
                    hasData: false,
                    hasEnoughActiveEmployees: true
                };
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    parsedResult.hasEnoughActiveEmployees = false;
                    resolve(parsedResult);
                } else if (err) {
                    reject(err);
                } else {
                    parsedResult.hasData = result.results.some(enpsResult => { return enpsResult.result !== null; });
                    result.results.forEach(function (enpsResult) {
                        parsedResult.results.push(
                            {
                                date: Dates.fromAPIFormat(enpsResult.from, APISettings.apiDateFormat),
                                value: enpsResult.result
                            }
                        );
                    });
                    resolve(parsedResult);
                }
            });
        });
    };

    const buildScoreOrFactorPromise = (scoreOrFactor: string, params: { hasFilters: boolean; from: Date; to: string }, comparisonGroup: ComparisonGroup): Promise<any> => {

        return new Promise((resolve, reject) => {

            const scoreId = scoreOrFactor.split('_')[1];
            const factorId = scoreOrFactor.includes('FACTOR_') ? scoreOrFactor.split('_')[2] : null;


            ScoresV2Srvc.stats.get(scoreId, params, function (err, result) {

                const parsedResult: EngagementAnalyzeBoxResult = {
                    id: scoreOrFactor,
                    groupName: comparisonGroup.name,
                    metricName: scoreAndFactorNames[factorId || scoreId],
                    group: comparisonGroup.id,
                    color: comparisonGroup.color,
                    results: [],
                    hasEnoughActiveEmployees: true,
                    hasData: false
                };

                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    parsedResult.hasEnoughActiveEmployees = false;
                    resolve(parsedResult);
                } else if (err) {
                    reject(err);
                } else {
                    parsedResult.hasData = result.results.some(scoreResult => { return scoreResult.quality !== 'NO_DATA'; });
                    result.results.forEach(function (scoreResult) {
                        if (factorId === null) {
                            parsedResult.results.push(
                                {
                                    date: Dates.fromAPIFormat(scoreResult.from, APISettings.apiDateFormat),
                                    value: scoreResult.result

                                }
                            );
                        } else {

                            const factorResult = _.find(scoreResult.factors, function (factor) { return factor.id == factorId; });
                            parsedResult.results.push(
                                {
                                    date: Dates.fromAPIFormat(scoreResult.from, APISettings.apiDateFormat),
                                    value: factorResult.result

                                }
                            );
                        }
                    });
                    resolve(parsedResult);
                }

            });
        });

    }

    useEffect(() => {

        if (indicatorsFilters && comparisonGroups.length > 0 && fromDate && to) {

            const promises: Promise<any>[] = [];

            comparisonGroups.forEach(comparisonGroup => {

                //First, build the request params

                const params = {
                    from: fromDate,
                    to: to,
                    hasFilters: false
                };

                if (comparisonGroup.filters) {
                    if (comparisonGroup.filters['hierarchyIds'] && comparisonGroup.filters['hierarchyIds'].length > 0) {
                        if (comparisonGroup.filters['hierarchyIds'].some((h) => { return h != null; })) {
                            params.hierarchyId = comparisonGroup.filters['hierarchyIds'];
                        }
                        params.hasFilters = true;
                    }
                    if (comparisonGroup.filters['segments'] && comparisonGroup.filters['segments'].length > 0) {
                        params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(comparisonGroup.filters['segments']);
                        params.hasFilters = true;

                    }
                    if (comparisonGroup.filters['groups'] && comparisonGroup.filters['groups'].length > 0) {
                        params.groupId = comparisonGroup.filters['groups'];
                        params.hasFilters = true;
                    }
                }




                // Now, based on the selected indicators, we will build the request
                Object.keys(indicatorsFilters).forEach(function (key) {



                    if (key === 'primary') {
                        if (indicatorsFilters[key]?.indexOf('HI') > -1) {
                            promises.push(buildHIPromise(params, comparisonGroup));
                        }
                        if (indicatorsFilters[key]?.indexOf('ENPS') > -1) {
                            promises.push(buildENPSPromise(params, comparisonGroup))
                        }
                    } else if (key === 'score') {
                        indicatorsFilters[key].forEach(function (scoreOrFactor) {
                            promises.push(buildScoreOrFactorPromise(scoreOrFactor, params, comparisonGroup))

                        })

                    }
                });
            });


            Promise.all(promises).then((results) => {
                if (results.length > 0) {
                    setResults(results);
                } else {
                    setResults([]);
                }

            }).catch((err) => {
                ErrorSvrc.showErrorModal(err);
            })


        }

    }, [comparisonGroups, indicatorsFilters, fromDate, to]);


    /*
        Check for duplicate filters
     */

    const verifyDuplicateFilters = (id: string, filters: FilterSelected) => {
        return comparisonGroups.some((otherGroup) => {
            return id !== otherGroup.id && JSON.stringify(filters) === JSON.stringify(otherGroup.filters);
        });
    };


    /*
    * Add a new comparison group
     */
    const addComparisonGroup = useCallback(() => {
        const newGroupId = `COMPARISON_${comparisonGroups.length}`;


        const color = ColorUtilities.palettes.chartsNamed[comparisonGroups.length % ColorUtilities.palettes.chartsNamed.length];
        const colorName = ColorUtilities.names[comparisonGroups.length % ColorUtilities.names.length];

        const newGroup: ComparisonGroup = {
            id: newGroupId,
            color: color, // Replace with dynamic color generation
            tagColor: colorName,
            filters: null,
            duplicate: false,
            hierarchyFiltersConfig: hierarchyFiltersConfig,
            groupFiltersConfig: groupFiltersConfig,
            segmentFiltersConfig: segmentFiltersConfig,
            name: null
        };

        setComparisonGroups((prevGroups) => [...prevGroups, newGroup]);
    }, [comparisonGroups]);

    const updateComparisonGroupFilters = (groupId: string, newFilters: FilterSelected) => {

        buildNameFromGroup(newFilters).then((name) => {


            setComparisonGroups((prevGroups) =>
                prevGroups.map((group) => {
                    if (group.id === groupId) {
                        const duplicate = verifyDuplicateFilters(groupId, newFilters);
                        return {
                            ...group,
                            filters: newFilters,
                            name: name,
                            duplicate
                        };
                    } else {
                        return group;
                    }
                })
            );


        }).catch((err) => {
            ErrorSvrc.showErrorModal(err);
        });


    }

    const removeComparisonGroup = (groupId: string) => {
        setComparisonGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== groupId)
        );
    };

    const hasComparisonOrFilters = useCallback(() => {
        if (!indicatorsFilters || !comparisonGroups) { return false; }
        const hasFilters = Object.keys(indicatorsFilters).some(
            (key) => indicatorsFilters[key] && indicatorsFilters[key].length > 0
        );
        const hasComparisons = comparisonGroups.some((group) => group.filters);
        return hasFilters && hasComparisons;
    }, [indicatorsFilters, comparisonGroups]);

    return (
        <Box className="analyze-box" sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography component={'span'} variant="caption" color="textSecondary">
                            {moment(fromDate).utc().format('DD MMM YYYY')} - {moment(to).format('DD MMM YYYY')}
                        </Typography>
                        <HFInfoIconWithTooltip
                            tooltipContent={$translate.instant('ENGAGEMENT_ANALYZE_DATES_TOOLTIP')}
                            icon={'INFO'}
                            color={'primary'}
                        />
                    </Box>

                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">
                        {$translate.instant('ENGAGEMENT_ANALYZE_INDICATORS_TITLE')}
                    </Typography>
                    <HfFilterMenu
                        selectedFilters={indicatorsFilters}
                        updateFilters={setIndicatorsFilters}
                        optionFiltersConfig={indicatorsFiltersConfig} />
                </Grid>

                <Grid item xs={12}>

                    <Stack direction={'column'} gap={1}>
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography component={'span'} variant="h6" >
                                {$translate.instant('ENGAGEMENT_ANALYZE_COMPARE_TITLE')}
                            </Typography>
                            <HFInfoIconWithTooltip
                                tooltipContent={$translate.instant('ENGAGEMENT_ANALYZE_COMPARE_DESCRIPTION')}
                                icon={'INFO'}
                                color={'primary'}
                            />
                        </Box>

                        <Typography variant="caption" color="textSecondary">
                            {$translate.instant('ENGAGEMENT_ANALYZE_COMPARE_MAX_ITEMS')}
                        </Typography>
                    </Stack>



                </Grid>

                {comparisonGroups.map((group) => (
                    <Grid item xs={12} key={group.id}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>

                            <HFCircleDot color={group.color} />

                            <HfFilterMenu
                                key={group.id}
                                selectedFilters={group.filters}
                                updateFilters={(filters: FilterSelected) => updateComparisonGroupFilters(group.id, filters)}
                                groupFiltersConfig={group.groupFiltersConfig}
                                segmentFiltersConfig={group.segmentFiltersConfig}
                                hierarchyFiltersConfig={group.hierarchyFiltersConfig}
                                tag-color={group.tagColor} />

                            {group.duplicate && (
                                <Tooltip title={$translate.instant('ENGAGEMENT_ANALYZE_COMPARE_DUPLICATED_TOOLTIP')}>
                                    <IconButton>
                                        <i className="fa fa-exclamation-triangle text-warning" />
                                    </IconButton>
                                </Tooltip>)}


                            <IconButton onClick={() => removeComparisonGroup(group.id)}>
                                <i className="fa fa-trash" />
                            </IconButton>
                        </Stack>
                        <Divider />
                    </Grid>
                ))}

                <Grid item xs={12}>
                    {comparisonGroups.length < 5 && (
                        <Link component="button" onClick={addComparisonGroup}>
                            {$translate.instant('ENGAGEMENT_ANALYZE_ADD_COMPARISON')}
                        </Link>
                    )}
                </Grid>

                {hasComparisonOrFilters() ? (
                    <Grid item xs={12}>
                        <EngagementAnalyzeBoxChart results={results} />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <EmptyState
                            image="images/analyze_no_data.png"
                            small
                            message={$translate.instant(
                                'ENGAGEMENT_ANALYZE_SELECT_INDICATORS_EMPTY_STATE'
                            )}
                            submessage={$translate.instant(
                                'ENGAGEMENT_ANALYZE_SELECT_INDICATORS_EMPTY_STATE_DESCRIPTION'
                            )}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};


export default EngagementAnalyzeBox;
