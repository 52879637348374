import React from 'react';
import { angularize } from 'reactInAngular';
import IdeaFeedbackType from './types/IdeaFeedbackType';
import SomethingIsWrongFeedbackType from './types/SomethingIsWrongFeedbackType';
import StandardFeedbackType from './types/StandardFeedbackType';
import {
    AnnouncementComment,
    CheckInComment,
    ClassificationFollowupComment,
    CommentBase,
    ENPSComment,
    FeedbackType,
    IdeaComment,
    RecognitionComment,
    ScoreComment,
    SomethingIsWrongComment,
    StandardComment,
    SurveyComment,
} from '../types';
import RecognitionFeedbackType from './types/RecognitionFeedbackType';
import ScoreFeedbackType from './types/ScoreFeedbackType';
import ENPSFeedbackType from './types/ENPSFeedbackType';
import angular from 'angular';
import HIClassificationFollowUpFeedbackType from './types/HIClassificationFollowUpFeedbackType';
import CheckInFeedbackType from './types/CheckInFeedbackType';
import SurveyFeedbackType from './types/SurveyFeedbackType';
import AnnouncementFeedbackType from './types/AnnouncementFeedbackType';



interface CommentTypeHandlerProps {
    comment: CommentBase;
    showConversationLink?: boolean;
    showReplyWithAnnouncementLink?: boolean;
    showModerateAction?: boolean;
    showSwapPrivacy?: boolean;
    hideHeader?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const CommentTypeHandler: React.FC<CommentTypeHandlerProps> = ({
    comment,
    showConversationLink = true,
    showReplyWithAnnouncementLink = true,
    showModerateAction = true,
    showSwapPrivacy = true,
    hideHeader = false,
    onCommentUpdated,
}) => {


    if (!comment) {
        return null;
    }


    const handleCommentUpdated = (err: any, updatedCommentId: string) => {

        if (onCommentUpdated) {
            onCommentUpdated(err, updatedCommentId);
        }
    }

    switch (comment.feedbackType) {
        case FeedbackType.RECOGNITION:
            return (
                <RecognitionFeedbackType
                    key={comment.id}
                    comment={comment as RecognitionComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                    showModerateLink={showModerateAction}
                    showSwapPrivacy={showSwapPrivacy}
                    showReplyWithAnnouncementLink={showReplyWithAnnouncementLink}
                />
            );
        case FeedbackType.CRITICISM:
            return (
                <SomethingIsWrongFeedbackType
                    key={comment.id}
                    comment={comment as SomethingIsWrongComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                    showModerateLink={showModerateAction}
                    showSwapPrivacy={showSwapPrivacy}
                    showReplyWithAnnouncementLink={showReplyWithAnnouncementLink}
                />
            );
        case FeedbackType.SUGGESTION:
            return (
                <IdeaFeedbackType
                    key={comment.id}
                    comment={comment as IdeaComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                    showModerateLink={showModerateAction}
                    showSwapPrivacy={showSwapPrivacy}
                    showReplyWithAnnouncementLink={showReplyWithAnnouncementLink}
                />
            );
        case FeedbackType.INFORMATION:
        case FeedbackType.CONGRATULATION:
        case FeedbackType.OTHER:
            return (
                <StandardFeedbackType
                    key={comment.id}
                    comment={comment as StandardComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                    showModerateLink={showModerateAction}
                    showSwapPrivacy={showSwapPrivacy}
                    showReplyWithAnnouncementLink={showReplyWithAnnouncementLink}
                />
            );
        case FeedbackType.SCORE:
            return (
                <ScoreFeedbackType
                    key={comment.id}
                    comment={comment as ScoreComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                />
            );
        case FeedbackType.CLASSIFICATION_FOLLOWUP:
            return (
                <HIClassificationFollowUpFeedbackType
                    key={comment.id}
                    onCommentUpdated={handleCommentUpdated}
                    comment={comment as ClassificationFollowupComment}
                    showConversationLink={showConversationLink}
                />
            );
        case FeedbackType.QUESTION:
            return (
                <CheckInFeedbackType
                    key={comment.id}
                    hideHeader={hideHeader}
                    comment={comment as CheckInComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                    showModerateLink={showModerateAction}
                    showSwapPrivacy={showSwapPrivacy}
                />
            );
        case FeedbackType.ENPS:
            return (
                <ENPSFeedbackType
                    key={comment.id}

                    comment={comment as ENPSComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                />
            );
        case FeedbackType.SURVEY:
            return (
                <SurveyFeedbackType
                    key={comment.id}
                    hideHeader={hideHeader}
                    comment={comment as SurveyComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                />
            );
        case FeedbackType.ANNOUNCEMENT_COMMENT:
            return (
                <AnnouncementFeedbackType
                    key={comment.id}
                    comment={comment as AnnouncementComment}
                    onCommentUpdated={handleCommentUpdated}
                    showConversationLink={showConversationLink}
                    showModerateLink={showModerateAction}
                />
            );

        default:
            return <div>Not yet</div>;
    }
};

angularize(CommentTypeHandler, 'hfCommentTypeHandler', angular.module('happyForceApp'), {
    comment: '<',
    showConversationLink: '<?',
    showReplyWithAnnouncementLink: '<?',
    showChangeVisibilityAction: '<?',
    showSwapPrivacy: '<',
    hideHeader: '<?',
    onCommentUpdated: '<',
});

export default CommentTypeHandler;
