import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, {DashStyleValue, Options} from 'highcharts';

interface EngagementAnalyzeBoxResult {
    id: string;
    group: string;
    color: string;
    groupName: string;
    metricName: string;
    hasEnoughActiveEmployees: boolean;
    hasData: boolean;
    results: Array<{
        date: string;
        value: number | null;
    }>;
}

interface EngagementAnalyzeBoxChartProps {
    results: EngagementAnalyzeBoxResult[];
}

const EngagementAnalyzeBoxChart: React.FC<EngagementAnalyzeBoxChartProps> = ({ results }) => {
    const [chartConfig, setChartConfig] = useState<Options | null>(null);

    const renderChart = () => {
        const oneDayOffset = 3600 * 1000 * 24;

        let hasENPS = false;
        let hasHI = false;
        let hasScores = false;
        const series: Highcharts.SeriesOptionsType[] = [];


        const scoreSeriesStyles: DashStyleValue[] = [
            'ShortDot',
            'ShortDashDot',
            'ShortDashDotDot',
            'Dot', 'Dash', 'LongDash', 'DashDot', 'LongDashDot',
            'LongDashDotDot'
        ];

        const addedTypeIds: string[] = [];

        results.forEach(data => {
            let yAxisId = 'SCORES';
            let dashStyle: Highcharts.DashStyleValue = 'ShortDot';
            const groupId = data.group;

            if (data.id === 'HI') {
                yAxisId = 'HI';
                hasHI = true;
                dashStyle = 'Solid';
            } else if (data.id === 'ENPS') {
                yAxisId = 'ENPS';
                hasENPS = true;
                dashStyle = 'ShortDash';
            } else {
                hasScores = true;
                const dashStyleIndex = addedTypeIds.indexOf(data.id);
                dashStyle = scoreSeriesStyles[dashStyleIndex === -1 ? addedTypeIds.length : dashStyleIndex % scoreSeriesStyles.length];
            }

            if (!addedTypeIds.includes(data.id)) {
                addedTypeIds.push(data.id);
                series.push({
                    id: `SERIE_${data.id}`,
                    dashStyle,
                    color: 'black',
                    name: data.metricName,
                    yAxis: yAxisId,
                    type: 'line',
                    data: [],
                    marker: {
                        symbol: 'circle'
                    }
                } as Highcharts.SeriesLineOptions);
            }

            const serieData: Highcharts.SeriesLineOptions = {
                id: `${groupId}_${data.id}`,
                dashStyle,
                color: data.color,
                name: data.groupName,
                custom: {
                    metricName: data.metricName
                },
                yAxis: yAxisId,
                type: 'line',
                data: data.results.map(result => ({
                    x: Date.parse(result.date) + oneDayOffset,
                    y: result.value !== null && result.value ? Math.round(result.value * 10) / 10 : null
                })),
                linkedTo: `SERIE_${data.id}`,
                marker: {
                    symbol: 'circle'
                }
            };

            series.push(serieData);
        });

        setChartConfig({
            chart: {
                marginTop: 30,
                zoomType: 'xy',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#6c757d',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal'
                }
            },
            title: { text: null },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    day: '%e %b',
                    month: '%b \'%y',
                    year: '%b \'%y'
                },
                startOnTick: false,
                title: { text: null }
            },
            yAxis: [
                {
                    id: 'HI',
                    visible: hasHI,
                    title: { align: 'high', text: 'HI', offset: 0, rotation: 0, y: -20, x: -17 },
                    min: 0,
                    max: 100,
                    tickAmount: 5
                },
                {
                    id: 'ENPS',
                    visible: hasENPS,
                    title: { align: 'high', text: 'eNPS', offset: 0, rotation: 0, y: -20, x: -10 },
                    min: -100,
                    max: 100,
                    tickAmount: 5,
                    opposite: hasHI && !hasScores
                },
                {
                    id: 'SCORES',
                    visible: hasScores,
                    title: { align: 'high', text: 'Scores', offset: 0, rotation: 0, y: -20 },
                    opposite: hasENPS || hasHI,
                    min: 0,
                    max: 10,
                    tickAmount: 5
                }
            ],
            tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                    const groupByName = this.points?.reduce((acc, point) => {
                        const name = point.series.name || '';
                        if (!acc[name]) {acc[name] = [];}
                        acc[name].push(point);
                        return acc;
                    }, {});

                    const formatedDate = Highcharts.dateFormat('%e %b, %Y', this.x as number);
                    let tooltipHTML = `<div style="margin-bottom:4px; font-weight:bold">${formatedDate}</div>`;

                    for (const [groupName, points] of Object.entries(groupByName || {})) {
                        tooltipHTML += '<div style="margin-bottom: 6px; border-top: 1px solid #ddd; padding-top: 4px;">';
                        tooltipHTML += `<span style="color:${points[0].color}; font-weight:bold">${groupName}</span><br>`;

                        (points as any[]).forEach(point => {
                            tooltipHTML += `
                                <span style="color:${point.color}">
                                    ${point.series.userOptions.custom.metricName}:
                                    <b>${point.y} pts</b>
                                </span><br>`;
                        });

                        tooltipHTML += '</div>';
                    }

                    return tooltipHTML;
                }
            }
            ,
            legend: { symbolWidth: 40 },
            credits: { enabled: false },
            plotOptions: { series: { connectNulls: true } },
            series
        });
    };

    useEffect(() => {
        renderChart();
    }, [results]);

    return chartConfig ? (
        <HighchartsReact highcharts={Highcharts} options={chartConfig} />
    ) : null;
};


export {EngagementAnalyzeBoxChart, EngagementAnalyzeBoxResult};
